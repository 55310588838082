<template>
  <div class="form-card p-4">
    <b-row>
      <b-col md="12">
        <h2>{{ form.name }}</h2>
      </b-col>
    </b-row>

    <b-row class="description">
      <b-col class="col-description">
        <p><strong>Descrição:</strong> {{ form.description }}</p>
      </b-col>
    </b-row>

    <b-row class="mt-3 bottom">
      <b-col cols="5">
        <span class="autoFillBtnLabel">
          {{ this.form.active ? 'Ativo' : 'Inativo' }}
        </span>
        <toggle-button
          v-can="'ConfConReg2'"
          class="autoFillBtn mb-1"
          :width="30"
          :value="Boolean(form.active)"
          :sync="true"
          :height="18"
          :color="{ checked: '#00C773', unchecked: '#E1E5F4' }"
          @change="updateActive()"
        />
      </b-col>
      <b-col cols="7">
        <router-link v-can="'ConfConReg1'" :to="link" tag="span" class="link">
          Ver registro
        </router-link>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'FormCardRecord',
  components: {},
  props: {
    form: Object,
    link: String
  },
  mounted() {
    this.updateDefault(this.form.default)
  },
  data() {
    return {
      color: null
    }
  },
  methods: {
    async updateActive() {
      try {
        let defaultForm = this.form.default
        if (this.form.default && this.form.active) {
          defaultForm = false
        }
        const { data } = await this.api.updateStandard(this.form.id, {
          active: !this.form.active,
          default: defaultForm
        })
        if (data.active) {
          this.$toast.success('Formulário ativado com sucesso!')
        } else {
          this.$toast.success('Formulário desativado com sucesso!')
        }
        this.$emit('reloadForms')
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    updateDefault(value) {
      if (value) {
        this.color = '#FFCC18'
      }
      this.color = 'white'
    }
  },
  watch: {
    'form.default'(value) {
      this.updateDefault(value)
    }
  }
}
</script>

<style lang="scss" scoped>
.form-card {
  width: 100%;
  text-align: left;
  height: 100%;
  background: var(--neutral-000);
  border-radius: 8px;
  padding: 1rem;

  h2 {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 1.3rem;
    color: var(--type-active);
    margin: 0;
  }

  .star {
    margin: 0 auto;
    margin-left: auto;
  }

  .description {
    margin-top: 1.5rem;
    height: 100px;
    text-overflow: ellipsis;
    height: 4rem;

    .col-description {
      p {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-size: 14px;
        line-height: 1.2rem;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box !important;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        white-space: normal;
        margin: 0;
      }
    }
  }
  .bottom {
    .autoFillBtnLabel {
      margin: 0;
    }

    .link {
      font-weight: 700;
      font-size: 16px;
      padding: 0;
      width: 100%;
      display: block;
      text-align: end;
      @media (min-height: 1600) {
        font-size: 1.6vh;
      }
      @media (max-height: 1600) {
        font-size: 1vw;
      }
    }

    label {
      margin-bottom: -5%;
    }
  }
}
</style>
